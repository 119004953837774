import dayGridPlugin from "@fullcalendar/daygrid";
import interactionPlugin from '@fullcalendar/interaction';
import FullCalendar from "@fullcalendar/react";
// import 'bootstrap/dist/css/bootstrap.css';
import bootstrapPlugin from '@fullcalendar/bootstrap';
import CreateDeliveries from "./CreateDeliveries";
import {useState} from "react";
import Table from 'react-bootstrap/Table';
import {useFormik} from "formik";
import { useNavigate } from "react-router-dom";
import DeleteDeliveries from "./DeleteDeliveries";
// import axios from "axios";
const DEBUG = true;
const NO_CLICKS = false;
const CLICKED_ONCE = true;
// const START_DATE_KEY = 'START_DATE';
// const END_DATE_KEY = 'END_DATE';
// const START_DATE = '2024-11-01'; // todo make dynamic
// const END_DATE = '2024-11-28'; // todo make dynamic

/**
 * todo: 1. why cant I click on an existing booking? editable={true} DONE
 *       2. how to detect to delete an existing? FE or BE? FE is more user friendly, BE makes more sense
 *
 * @param calendarEvents
 * @param isLoading
 * @returns {JSX.Element}
 * @constructor
 */
const Home = ({ calendarEvents, isLoading }) => {
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const [clickState, setClickState] = useState(NO_CLICKS);

    const navigate = useNavigate();

    console.log(calendarEvents);

    const formikCreate = useFormik({
        initialValues: {
            bookingStartDate: '',
        },
        // validationSchema: Yup.object({
        //     bookingStartDate: Yup.string().required('* Required'),
        // }),

        onSubmit: async (values, { setFormSubmitted, resetForm }) => {
            console.log(values);
            console.log(`start date: ${startDate} - end date: ${endDate}`)
            if (doDatesExist(startDate, endDate)) {

                await DeleteDeliveries(startDate, endDate)
                    // .then(() => {
                    resetForm();
                    setEndDate(null);
                    setStartDate(null);
                    navigate(0);
                // }).catch((error) => {
                //     console.error('Error deleting booking:', error);
                // });
            } else {

                await CreateDeliveries(startDate, endDate)
                    // .then(() => {
                    // setFormSubmitted(true);
                    // calendarEvents.push();
                    resetForm();
                    setEndDate(null);
                    setStartDate(null);
                    navigate(0);
                // }).catch((error) => {
                //     console.error('Error creating booking:', error);
                // });
            }
        },
    });

    const handleDateClick = (arg) => {
        DEBUG && console.log(arg);

        if (clickState) {
            let end = arg.dateStr;
            DEBUG && console.log(`end date: ${end}`);
            setEndDate(end);
            setClickState(NO_CLICKS);
        } else {
            let start = arg.dateStr;
            DEBUG && console.log(`start date: ${start}`);
            setStartDate(start);
            // localStorage.setItem(START_DATE_KEY, start);
            setClickState(CLICKED_ONCE);
        }
    }

    function doDatesExist(startDate, endDate) {
        let matches = [];

        // calendarEvents.map((booking) => { // LABEL BUG
        (calendarEvents || []).forEach((booking) => {
            let bookingDate = booking.date;
            if (bookingDate === startDate || bookingDate === endDate) matches.push(bookingDate);
        });

        return matches.length > 0;
    }

    const formatDate = (dateString) => {
        if (!dateString) return "";
        const options = { month: "short", day: "numeric" }
        return new Date(dateString).toLocaleDateString(undefined, options)
    }

    return (
        <>
            { (startDate || endDate) && (

                <Table striped bordered hover>
                    <thead>
                    <tr>
                        <th>From</th>
                        <th>To</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr>
                        <td>{formatDate(startDate)}</td>
                        <td>{formatDate(endDate)}</td>
                    </tr>
                    </tbody>
                </Table>
                )
            }

            { startDate && endDate && (
                <div id="form-flex-container" className="round-form">
                    <div id="form-container">
                        <form onSubmit={formikCreate.handleSubmit}>
                            <button type="submit">{ doDatesExist(startDate, endDate) ? 'Delete' : 'Create' }</button>
                            <button type="submit">Cancel</button>
                        </form>
                    </div>
                </div>
            )}

            <div className="flex-center">
                <FullCalendar
                    plugins={[ dayGridPlugin, bootstrapPlugin, interactionPlugin ]}
                    events={calendarEvents}
                    initialView="dayGridMonth"
                    contentHeight={400}
                    editable={true}
                    selectable={true}
                    dateClick={handleDateClick}
                />
            </div>
        </>
    );
};

export default Home;