
import axios from "axios";
const DELIVERY_SERVICE_URL = "https://atnse82ea9.execute-api.eu-west-1.amazonaws.com/prod/events";

const GetDeliveries = (startDate) => {
    const httpConfig = {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
        },
    };
    console.log("Class: fetchDeliveries, Line 20 (start): ", startDate);
    return axios(`${DELIVERY_SERVICE_URL}?listAll=true&startDate=${startDate}`, httpConfig);
}

export default GetDeliveries;