
import axios from "axios";
const DELIVERY_SERVICE_URL = "https://atnse82ea9.execute-api.eu-west-1.amazonaws.com/prod/events";

/**
 * in theory we should pass the ID in, not the start & enddate but we may have to cancel part of the event
 * so let's leave the ID in the path for now
 * @param startDate
 * @param endDate
 * @returns {Promise<Promise<axios.AxiosResponse<any>> | *>}
 * @constructor
 */
const DeleteDeliveries = async (startDate, endDate) => {
    const httpConfig = {
        method: "DELETE",
        // data: {
        //     startDate: startDate,
        //     endDate: endDate,
        // },
        headers: {
            "Content-Type": "application/json",
        },
    };
    console.log("Class: fetchDeliveries, Line 20 (start): ", startDate);
    console.log(`startDate=${startDate}&endDate=${endDate}`);
    return axios(`${DELIVERY_SERVICE_URL}/123/?startDate=${startDate}&endDate=${endDate}`, httpConfig);
}

export default DeleteDeliveries;