import { Amplify }  from 'aws-amplify';
import React, {useEffect, useState} from 'react';
import Navbar from 'react-bootstrap/Navbar';
import './App.css';
import {Authenticator, ThemeProvider} from '@aws-amplify/ui-react';
import config from './amplifyconfiguration.json';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Home from "./Home";
import getDeliveries from "./GetDeliveries";
Amplify.configure(config);
const LABEL = 'Booked';
const DEFAULT_DATE = '2023-01-01';

function App() {
    const [calendarEvents, setCalendarEvents] = useState([]);
    const [startDate, setStartDate] = useState(DEFAULT_DATE);
    const [isLoading, setIsLoading] = useState(true);

    useEffect( () => {
        async function getAddressFromBackend(startDate) {
            let events = [];
            const response = await getDeliveries(startDate);
            if (response && response.hasOwnProperty('status') && response.status === 200) {
                setCalendarEvents(mapData(response.data));
            }
        }
        getAddressFromBackend(startDate);
    }, [startDate]);

    /**
     * Handle events with multiple days by looping over, if start != end:
     *  "endDate": "2023-11-11",
     *  "startDate": "2023-11-09",
     * @param events
     * @returns {*}
     */
    const mapData = (events) => {
        let mappedEvents = [];
        // if return to storing timestamps in the DB, use booking.startDate.split('T')[0]
        // return events.map(booking => { return { title: LABEL, date: booking.startDate }});
        for (const index in events) {
            // console.log(events[index]);
            const booking = events[index];
            const numberOfDays = booking.numberOfDays;// could use this instead
            const startDate = booking.startDate;
            const endDate = booking.endDate;
            if (endDate === startDate) {
                mappedEvents.push( { title: LABEL, date: booking.startDate } );
            } else {
                let start = new Date(startDate);
                let date = new Date(startDate);
                for (let i = 0; i < numberOfDays; i++) {
                    date.setDate(start.getDate() + i);
                    let labelDate = date.toISOString().split('T')[0];
                    mappedEvents.push( { title: LABEL, date: labelDate } );
                }
            }
        }
        return mappedEvents;
    }

    const theme = {
        name: 'custom-button-theme',
        tokens: {
            components: {
                button: {
                    fontWeight: { value: '{fontWeights.black.value}' },
                    primary: {
                        backgroundColor: { value: 'rebeccapurple' },
                        _hover: {
                            backgroundColor: { value: 'hotpink' },
                        },
                    },
                },
            },
        },
    };

    // console.log('%c Logs in colour.', 'background: #222; color: #bada55');
        return (
          <>
              <Authenticator components={{
                  SignIn: {
                      Header() {
                          return (
                              <div>
                                  <h1>Sign In to Your Account</h1>
                              </div>
                          );
                      },
                      // Footer() {
                      //     return <div>Footer content here</div>;
                      // },
                  }
              }}>
                  {({ signOut, user }) => (
                      <>
                      <Router>
                          <Navbar id="nav">
                              <div id="logos">
                                  <h1>Cottage Bookings</h1>
                              </div>
                          </Navbar>
                          <Routes>
                              <Route path="/" element={
                                  <Home
                                      calendarEvents={calendarEvents}
                                      isLoading={isLoading}
                                  />
                              }
                              />
                          </Routes>
                      </Router>
                          <ThemeProvider theme={theme}>
                              <button variation="primary">Sign out</button>
                          </ThemeProvider>
                      </>
                  )}
              </Authenticator>

          </>
      );
}

export default App;
// const CustomSignIn = () => (
//     <SignIn hideSignUp={true} />
// );
// export default withAuthenticator(App, {
//
//     components: {
//         SignIn: SignIn,
//     },
// });