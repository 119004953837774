
import axios from "axios";
const DELIVERY_SERVICE_URL = "https://atnse82ea9.execute-api.eu-west-1.amazonaws.com/prod/events";

const CreateDeliveries = async (startDate, endDate) => {
    const httpConfig = {
        method: "POST",
        // data: {
        //     startDate: startDate,
        //     endDate: endDate,
        // },
        headers: {
            "Content-Type": "application/json",
        },
    };
    console.log("Class: fetchDeliveries, Line 20 (start): ", startDate);
    console.log(`startDate=${startDate}&endDate=${endDate}`);
    return axios(`${DELIVERY_SERVICE_URL}?startDate=${startDate}&endDate=${endDate}`, httpConfig);
}

export default CreateDeliveries;